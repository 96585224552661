<template>
  <div class="committee">
    <h3 class="sub-title">Local Organizing Committee</h3>
    <div class="list-group">
      <div class="list-group-item">
        <div class="list-group-content list-title">
          <div class="t-lt">Position</div>
          <div class="t-mid">Name</div>
          <div class="t-rt">Affiliation</div>
        </div>
      </div>
      <div class="list-group-item" v-for="(item, index) in committees" :key="index">
        <div class="list-group-content">
          <div class="left">
            <span>{{item.position}}</span>
          </div>
          <div class="mid">{{item.name}}</div>
          <div class="right">{{item.affiliation}}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Committee",
  data() {
    return {
      // committees: [
      //   {position: "Congress President", name: "Nianguo Dong", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      //   {position: "Vice Congress President", name: "Yongde Liao", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      //   {position: "Advisory Board", name: "Shengshou Hu", affiliation: "Fuwai Hospital, Chinese Academy of Medicine Science  and Perking Union Medical College" },
      //   {position: "Advisory Board", name: "Jie He", affiliation: "Cancer Hospital of Chinese Academy of Medical Sciences" },
      //   {position: "Advisory Board", name: "Jun Wang", affiliation: "Peking University People's Hospital, Peking University" },
      //   {position: "Advisory Board", name: "Jian Zhuang", affiliation: "Guangdong General Hospital,South China University of Technology" },
      //   {position: "Secretary General", name: "Jiahong Xia", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      //   {position: "Vice Secretary General", name: "Keneng Chen", affiliation: "Peking University Cancer Hospital" },
      //   {position: "Vice Secretary General", name: "Shu Chen", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      //   {position: "Vice Secretary General", name: "Yin Wang", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      //   {position: "Vice Secretary General", name: "Yixuan Wang", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      //   {position: "Vice Secretary General", name: "Ke Jiang", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      //   {position: "Vice Secretary General", name: "Chi Zhang", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      //   {position: "Chair, Scientific Committee", name: "Song Wan", affiliation: "Prince of Wales Hospital,The Chinese University of Hong Kong" },
      //   {position: "Chair, Scientific Committee", name: "Zhe Zheng", affiliation: "Fuwai Hospital, Chinese Academy of Medicine Science  and Perking Union Medical College" },
      //   {position: "Vice-Chair, Scientific Committee (Adult Cardiac)", name: "Chunsheng Wang", affiliation: "Zhongshan Hospital,Fudan University" },
      //   {position: "Vice-Chair, Scientific Committee (Adult Cardiac)", name: "Yingbin Xiao", affiliation: "Xinqiao Hospital,ARMY Medical University" },
      //   {position: "Vice-Chair, Scientific Committee (Thoracic)", name: "Shugeng Gao", affiliation: "Cancer Hospital,Chinese Academy of Medical Sciences" },
      //   {position: "Vice-Chair, Scientific Committee (Thoracic)", name: "Lunxu Liu", affiliation: "West China Hospital, Sichuan University" },
      //   {position: "Vice-Chair , Scientific Committee (Pediatric)", name: "Shoujun Li", affiliation: "Fuwai Hospital, Chinese Academy of Medicine Science  and Perking Union Medical College" },
      //   {position: "Vice-Chair , Scientific Committee (Pediatric)", name: "Haibo Zhang", affiliation: "Shanghai Children's Medical Center,Shanghai Jiao Tong University" },
      //   {position: "Vice-Chair , Scientific Committee (Aorta & Vascular)", name: "Liangwan Chen", affiliation: "Union Hospital,Fujian Medical University" },
      //   {position: "Vice-Chair , Scientific Committee (Aorta & Vascular)", name: "Junming Zhu", affiliation: "Beijing An Zhen Hospital, Capital Medical University" },
      //   {position: "Co-Chair, Finance Committee", name: "Huishan Wang", affiliation: "General Hospital of Northern Theater Command" },
      //   {position: "Co-Chair, Finance Committee", name: "Gening Jiang", affiliation: "Shanghai Pulmonary Hospital, Tongji University" },
      //   {position: "Chair, Public Relations Committee", name: "Xin Chen", affiliation: "Nanjing No.1 Hospital,Nanjing Medical University" },
      //   {position: "Co-Chair, Social Program Committee", name: "Qiang Zhao", affiliation: "Ruijin Hospital,Shanghai Jiao Tong University" },
      // ]
      committees: [
        {position: "Congress Honorary President", name: "Shengshou Hu", affiliation: "Fuwai Hospital, Chinese Academy of Medicine Science  and Perking Union Medical College" },
        {position: "Congress Honorary President", name: "Jie He", affiliation: "Cancer Hospital of Chinese Academy of Medical Sciences" },
        {position: "Congress Honorary President", name: "Jun Wang", affiliation: "Peking University People's Hospital, Peking University" },
        {position: "Congress Honorary President", name: "Jian Zhuang", affiliation: "Guangdong General Hospital,South China University of Technology" },
        {position: "The 11th Wuhan International Conference of Cardiovascular Disease President", name: "Shengshou Hu", affiliation: "Fuwai Hospital, Chinese Academy of Medicine Science  and Perking Union Medical College" },
        {position: "The 32th ASCVTS Annual Meeting—ASCVTS 2024 WUHAN President", name: "Nianguo Dong", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "The 32th ASCVTS Annual Meeting—ASCVTS 2024 WUHAN Vice President", name: "Zhe Zheng", affiliation: "Fuwai Hospital, Chinese Academy of Medicine Science  and Perking Union Medical College" },
        {position: "The 32th ASCVTS Annual Meeting—ASCVTS 2024 WUHAN Vice President", name: "Shugeng Gao", affiliation: "Cancer Hospital,Chinese Academy of Medical Sciences" },
        {position: "The 32th ASCVTS Annual Meeting—ASCVTS 2024 WUHAN Vice President", name: "Jiahong Xia", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "The 32th ASCVTS Annual Meeting—ASCVTS 2024 WUHAN Vice President", name: "Lunxu Liu", affiliation: "West China Hospital, Sichuan University" },
        {position: "The 32th ASCVTS Annual Meeting—ASCVTS 2024 WUHAN Vice President", name: "Yongde Liao", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "ASCVTS 2024 WUHAN Scientific Committee Chair", name: "Song Wan", affiliation: "Prince of Wales Hospital,The Chinese University of Hong Kong" },
        {position: "ASCVTS 2024 WUHAN Scientific Committee Chair", name: "Wei Feng", affiliation: "Fuwai Hospital of Chinese Academy of Medical Science" },
        {position: "ASCVTS 2024 WUHAN Scientific Committee Vice Chair (in alphabetical order)", name: "Jimei Chen", affiliation: "Guangdong Provincial People's Hospital" },
        {position: "ASCVTS 2024 WUHAN Scientific Committee Vice Chair (in alphabetical order)", name: "Keneng Chen", affiliation: "Peking University Cancer Hospital" },
        {position: "ASCVTS 2024 WUHAN Scientific Committee Vice Chair (in alphabetical order)", name: "Jincheng Liu", affiliation: "Xijing Hospital Affiliated to Air Force Military Medical University" },
        {position: "ASCVTS 2024 WUHAN Scientific Committee Vice Chair (in alphabetical order)", name: "Xiaofei Li", affiliation: "Xi'an International Medical Center Hospital Chest Hospital" },
        {position: "ASCVTS 2024 WUHAN Scientific Committee Vice Chair (in alphabetical order)", name: "Yingbin Xiao", affiliation: "Xinqiao Hospital,ARMY Medical University" },
        {position: "ASCVTS 2024 WUHAN Scientific Committee Vice Chair (in alphabetical order)", name: "Junming Zhu", affiliation: "Beijing An Zhen Hospital, Capital Medical University" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Chair", name: "Zhe Zheng", affiliation: "Fuwai Hospital, Chinese Academy of Medicine Science  and Perking Union Medical College" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Chair", name: "Jiahong Xia", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Vice Chair (in alphabetical order)", name: "Haiquan Chen", affiliation: "Fudan University Shanghai Cancer Center" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Vice Chair (in alphabetical order)", name: "Xin Chen", affiliation: "Nanjing No.1 Hospital,Nanjing Medical University" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Vice Chair (in alphabetical order)", name: "Jianhua Fu", affiliation: "Sun Yat-sen University Cancer Center" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Vice Chair (in alphabetical order)", name: "Shengli Jiang", affiliation: "General Hospital of the People's Liberation Army" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Vice Chair (in alphabetical order)", name: "Kexiang Liu", affiliation: "The second hospital of Jilin University" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Vice Chair (in alphabetical order)", name: "Lin Xu", affiliation: "The Second Affiliated Hospital of Shenyang Medical College" },
        {position: "ASCVTS 2024 WUHAN Organizing Committee Vice Chair (in alphabetical order)", name: "Qiang Zhao", affiliation: "Ruijin Hospital,Shanghai Jiao Tong University" },
        {position: "ASCVTS 2024 WUHAN Finance Committee Chair", name: "Huishan Wang", affiliation: "General Hospital of Northern Theater Command" },
        {position: "ASCVTS 2024 WUHAN Finance Committee Chair", name: "Gening Jiang", affiliation: "Shanghai Pulmonary Hospital, Tongji University" },
        {position: "ASCVTS 2024 WUHAN Public Relations Committee Chair", name: "Xin Chen", affiliation: "Nanjing No.1 Hospital,Nanjing Medical University" },
        {position: "ASCVTS 2024 WUHAN Social Program Committee Chair", name: "Qiang Zhao", affiliation: "Ruijin Hospital,Shanghai Jiao Tong University" },
        {position: "ASCVTS 2024 WUHAN Secretary General", name: "Jiahong Xia", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "ASCVTS 2024 WUHAN Secretary General", name: "Wei Feng", affiliation: "Fuwai Hospital of Chinese Academy of Medical Science" },
        {position: "ASCVTS 2024 WUHAN Secretary (in alphabetical order)", name: "Keneng Chen", affiliation: "Peking University Cancer Hospital" },
        {position: "ASCVTS 2024 WUHAN Secretary (in alphabetical order)", name: "Shu Chen", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "ASCVTS 2024 WUHAN Secretary (in alphabetical order)", name: "Zhan Hu", affiliation: "Fuwai Hospital of Chinese Academy of Medical Science" },
        {position: "ASCVTS 2024 WUHAN Secretary (in alphabetical order)", name: "Ke Jiang", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "ASCVTS 2024 WUHAN Secretary (in alphabetical order)", name: "Yin Wang", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "ASCVTS 2024 WUHAN Secretary (in alphabetical order)", name: "Yixuan Wang", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
        {position: "ASCVTS 2024 WUHAN Secretary (in alphabetical order)", name: "Chi Zhang", affiliation: "Union Hospital, Huazhong University of Science and Technology" },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.committee {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .list-group {
    .list-group-item {
      border: 1px solid rgba(0,0,0,.125);
      border-bottom: none;
      padding: 12px 20px;
      line-height: 26px;
      font-size: 15px;
      .list-group-content {
        display: flex;
        align-items: center;
        .t-lt {
          flex: 6;
        }
        .t-mid {
          flex: 2;
        }
        .t-rt {
          flex: 4;
        }
        .left {
          flex: 6;
          span {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              bottom: -3px;
              background: linear-gradient(to right, #19317e, #eaeff8);
              height: 3px;
              width: 100%;
            }
          }
        }
        .mid {
          flex: 2;
        }
        .right {
          flex: 4;
          font-family: "Akrobat Regular";
        }
        @media (max-width:992px) {
          display: block;
          &.list-title {
            div {
              display: none;
            }
          }
        }
      }
      &:first-child {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-radius: 0 0 4px 4px;
        border-bottom: 1px solid rgba(0,0,0,.125);
      }
      &:nth-of-type(2n + 1) {
        background-color: #fafafa;
      }
    }
  }
}
</style>
